<style>
    .bigData {
        background: url(./bigData/bg.png) no-repeat top center;
        background-size: 100% auto;
    }

    .bigData .title-top {
        line-height: 20px;
        padding: 12px 12px 0 12px;
        background: transparent;
        height: 50px;
    }

    .bigData .ant-tabs-bar {
        border-bottom: 1px solid #00eefb55;
        margin-bottom: 5px;
    }

    .bigData .ant-tabs-nav .ant-tabs-tab {
        color: #ffffff99;
        padding: 0 16px 12px 16px;
    }

    .bigData .ant-tabs-nav .ant-tabs-tab-active {
        color: #79f7f1;
    }

    .bigData .ant-tabs-ink-bar {
        background: #79f7f1;
    }

    .bigData .titlen {
        line-height: 58px;
        height: 58px;
        margin: 0;
        text-align: center;
        font-size: 18px;
        color: #79f7f1;
        background: url(./bigData/subtitle.png) no-repeat center center;
    }

    .bigData .total_bg {

        /*background: url(./bigData/total.png) no-repeat top center;*/
        background-image: url("./bigData/total.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 230px;
        background-position: center;
        margin-left: 100px;
    }

    ::-webkit-scrollbar {
        width: 0px;
    }

    .topDetail {
        width: 200px;;
        z-index: 1000;
        margin-left: 20px;
    }

    .numberTitle {
        font-weight: bold;
    }
</style>
<template>
    <a-layout v-if="!is_user" class="bigData" style="height: 100vh;background-color: #000c17">
        <a-layout-header class="title-top" style="margin-top: 20px">
            <h4 style="color:#79F7F1">
                <span style="float:right">{{tips}}</span>
            </h4>
            <img src="./bigData/title.png" style="width: 100%;position: relative;top: -30px"/>
        </a-layout-header>
        <a-layout style="padding:12px;background:transparent;height: calc(100vh - 50px);">
            <a-layout-sider width="20%" style="background: transparent;padding:0;height: 100%;">
                <techCard title="实时预警监控" style="height: 49%;display: flex;flex-direction: column">
                    <div style="height: 66px;">
                        <a-row :gutter="24" style="margin-bottom:20px;">
                            <a-col :span="6" style="text-align:center">
                                <img src="./bigData/icon1.png" style="width:60px">
                            </a-col>

                            <a-col :span="6">
                                <h1 class="numberTitle"
                                    style="text-align:center;color:#79F7F1;font-size:24px;line-height:44px;margin:0;">
                                    {{alarmMData.all_num}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                    今日预警/次</p>
                            </a-col>
                            <a-col :span="6">
                                <h1 class="numberTitle"
                                    style="text-align:center;color:#79F7F1;font-size:24px;line-height:44px;margin:0;">
                                    {{alarmMData.today_num}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                    累计预警/次</p>
                            </a-col>
                            <!-- <a-col :span="6">
                                 <h1 class="numberTitle"
                                     style="text-align:center;color:#79F7F1;font-size:24px;line-height:44px;margin:0;">
                                     {{total.alarm.today}}</h1>
                                 <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">
                                     今日处理/次</p>
                             </a-col>-->
                        </a-row>
                    </div>
                    <div style="color:#79F7F1;padding-top: 1px;font-size: 12px;background-color: #1A4444;margin-top: 20px">
                        <el-row style="width: 100%;">
                            <el-col :span="4" align="center">姓名</el-col>
                            <el-col :span="6" align="center">手机号</el-col>
                            <el-col :span="3" align="center">类型</el-col>
                            <el-col :span="2" align="center">值</el-col>
                            <el-col :span="9" align="center">预警时间</el-col>
                        </el-row>
                    </div>
                    <div style="height:calc(50vh - 74px - 52px - 66px - 56px)">
                        <vue-seamless-scroll :data="alarmMData.alarm_message" style="overflow-y:hidden;height: 100%"
                                             :class-option="classOption2">
                            <h6 v-for="(item,index) in alarmMData.alarm_message"
                                :key="'alarm'+(100+index)"
                                style="color:#79F7F1;margin:0;margin-top:15px;font-size: 10px !important;">
                                <a-row :gutter="5">
                                    <a-col :span="4" align="center">{{item.name}}</a-col>
                                    <a-col :span="6" align="center">{{item.mobile}}</a-col>
                                    <a-col :span="3" align="center">
                                        {{item.type_desc}}
                                    </a-col>
                                    <a-col :span="2" align="center" style="display: flex">
                                        <span v-if="item.status==1" style="color: red;margin-left: 3px">↑</span>
                                        <span v-if="item.status==2"
                                              style="color:rgb(254, 180, 51);margin-left: 3px ">↓</span>
                                        {{item.test_value}}
                                    </a-col>
                                    <a-col :span="9" align="center">{{item.add_time}}</a-col>
                                </a-row>
                            </h6>
                        </vue-seamless-scroll>
                    </div>
                </techCard>
                <techCard title="新增用户列表" style="margin-top: 10px">
                    <div style="height: 66px;">
                        <a-row :gutter="12" style="margin-bottom:20px;">
                            <a-col :span="6" style="text-align:center">
                                <img src="./bigData/icon2.png" style="width:60px">
                            </a-col>

                            <a-col :span="6">
                                <h1 class="numberTitle"
                                    style="text-align:center;color:#79F7F1;font-size:24px;line-height:44px;margin:0;">
                                    {{alarmMData.user_today_num}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">今日新增</p>
                            </a-col>
                            <a-col :span="6">
                                <h1 class="numberTitle"
                                    style="text-align:center;color:#79F7F1;font-size:24px;line-height:44px;margin:0;">
                                    {{alarmMData.user_month_num}}</h1>
                                <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">本月新增</p>
                            </a-col>
                            <!--  <a-col :span="6">
                                  <h1 class="numberTitle"
                                      style="text-align:center;color:#79F7F1;font-size:24px;line-height:44px;margin:0;">
                                      {{total.user_month}}</h1>
                                  <p style="text-align:center;font-size:12px;color:#fff;margin:0;font-weight:400">已绑定</p>
                              </a-col>-->
                        </a-row>
                    </div>
                    <div style="color:#79F7F1;padding-top: 1px;font-size: 12px;margin-top: 20px;background-color: #1A4444">
                        <a-row :gutter="5">
                            <a-col :span="4" align="Center">姓名</a-col>
                            <a-col :span="7" align="Center">手机号</a-col>
                            <a-col :span="2" align="Center">性别</a-col>
                            <a-col :span="5" align="Center">年龄</a-col>
                            <a-col :span="6" align="Center">添加时间</a-col>
                        </a-row>
                    </div>
                    <div style="height:calc(50vh - 74px - 52px - 66px - 56px);">
                        <vue-seamless-scroll :data="alarmMData.user_list" style="overflow-y:hidden;height: 100%"
                                             :class-option="classOption2">
                            <h6 @click="set_uid(item)" v-for="(item,index) in alarmMData.user_list"
                                :key="'alarm'+(100+index)"
                                style="color:#79F7F1;margin:0;margin-top:15px;font-size: 10px !important;cursor: pointer">
                                <a-row :gutter="5">
                                    <a-col :span="4" align="Center">{{item.name}}</a-col>
                                    <a-col :span="7" align="Center">{{item.mobile}}</a-col>
                                    <a-col :span="2" align="Center">{{item.sex=='1'?'男':'女'}}</a-col>
                                    <a-col :span="5" align="Center">{{item.birth}}</a-col>
                                    <a-col :span="6" align="Center">{{item.create_time}}</a-col>
                                </a-row>
                            </h6>
                        </vue-seamless-scroll>
                    </div>
                </techCard>
            </a-layout-sider>
            <a-layout-content style="width: 200px;background:#060B1B88;padding:15px 44px">
                <div>
                    <div class="titlen">健康设备分布</div>
                    <div id="map" style="height:calc(100vh - 68px - 58px);"></div>
                    <div style="position: absolute;left:460px;top:146px;height: 100px;width:calc(100% - 920px);
                   display: flex;justify-content: center">

                        <techCard class="topDetail">
                            <h6 style="text-align:Center;color:#79f7f1;font-size: 14px">血压血糖仪</h6>
                            <h4 style="text-align:Center;font-size:28px;margin:0;color:#79F7F1;font-weight: bold;">
                                {{total.device_bp}}</h4>
                        </techCard>

                        <techCard class="topDetail" style="margin-left:50px">
                            <h6 style="text-align:Center;color:#79f7f1;font-size: 14px">智能体脂称</h6>
                            <h4 style="text-align:Center;font-size:28px;margin:0;color:#FEB433;font-weight: bold;">
                                {{total.device_weight}}</h4>
                        </techCard>

                        <techCard class="topDetail" style="margin-left:50px">
                            <h6 style="text-align:Center;color:#79f7f1;font-size: 14px">通话手表</h6>
                            <h4 style="text-align:Center;font-size:28px;margin:0;color:#9CEB36;font-weight: bold;">
                                {{total.device_watch}}</h4>
                        </techCard>

                    </div>
                    <div style="position: absolute;left:460px;bottom:20px;height: 100px;width:calc(100% - 920px);
                   display: flex;justify-content: center;">
                        <techCard style="width:200px;margin-left: 10px">
                            <a-row>
                                <a-col :span="6">
                                    <i class="iconfont icon-xueya" style="color:#79f7f1;font-size:30px"></i>
                                </a-col>
                                <a-col :span="18">
                                    <h5 style="color:#fff;margin:0">血压采集</h5>
                                    <h6 style="color:#ffffffaa;margin:0">累计 <span style="font-size:16px;color:#79f7f1">{{total.bp.all}}</span>
                                        次</h6>
                                    <h6 style="color:#ffffffaa;margin:0">今日 <span style="font-size:16px;color:#79f7f1">{{total.bp.today}}</span>
                                        次</h6>
                                </a-col>
                            </a-row>
                        </techCard>
                        <techCard style="width:200px;margin-left: 10px">
                            <a-row>
                                <a-col :span="6">
                                    <i class="iconfont icon-xueya" style="color:#79f7f1;font-size:30px"></i>
                                </a-col>
                                <a-col :span="18">
                                    <h5 style="color:#fff;margin:0">血糖采集</h5>
                                    <h6 style="color:#ffffffaa;margin:0">累计 <span style="font-size:16px;color:#79f7f1">{{total.bs.all}}</span>
                                        次</h6>
                                    <h6 style="color:#ffffffaa;margin:0">今日 <span style="font-size:16px;color:#79f7f1">{{total.bs.today}}</span>
                                        次</h6>
                                </a-col>
                            </a-row>
                        </techCard>
                        <techCard style="width:200px;margin-left: 10px">
                            <a-row>
                                <a-col :span="6">
                                    <i class="iconfont icon-xinshuai" style="color:#79f7f1;font-size:30px"></i>
                                </a-col>
                                <a-col :span="18">
                                    <h5 style="color:#fff;margin:0">心率采集</h5>
                                    <h6 style="color:#ffffffaa;margin:0">累计 <span style="font-size:16px;color:#79f7f1">{{total.hr.all}}</span>
                                        次</h6>
                                    <h6 style="color:#ffffffaa;margin:0">今日 <span style="font-size:16px;color:#79f7f1">{{total.hr.today}}</span>
                                        次</h6>
                                </a-col>
                            </a-row>
                        </techCard>
                        <techCard style="width:200px;margin-left: 10px">
                            <a-row>
                                <a-col :span="6">
                                    <i class="iconfont icon-xueya" style="color:#79f7f1;font-size:30px"></i>
                                </a-col>
                                <a-col :span="18">
                                    <h5 style="color:#fff;margin:0">体脂采集</h5>
                                    <h6 style="color:#ffffffaa;margin:0">累计 <span style="font-size:16px;color:#79f7f1">{{total.weight.all}}</span>
                                        次</h6>
                                    <h6 style="color:#ffffffaa;margin:0">今日 <span style="font-size:16px;color:#79f7f1">{{total.weight.today}}</span>
                                        次</h6>
                                </a-col>
                            </a-row>
                        </techCard>
                    </div>
                </div>
            </a-layout-content>
            <a-layout-sider width="20%" style="background: transparent;padding:0;height: calc(100vh - 50px);">
                <techCard title="用户总体情况" style="color: white;height: 33vh">
                    <div style="height: 23vh;display: flex;align-items: center;justify-content: center;">
                        <img style="height: 100%" src="./bigData/total.png">
                        <div style="position: absolute;left: 20px;">
                            <div style="color:#79f7f1;font-size:24px;text-align:center;">
                                {{total.hyuser}}
                            </div>
                            <div style="color:#fff;text-align:center">今日活跃</div>
                        </div>
                        <div style="position: absolute;;top: 50px;">
                            <div style="color:#79f7f1;font-size:24px;text-align:center;;">
                                {{alarmMData.user_all_num}}
                            </div>
                            <div style="color:#fff;text-align:center">总注册</div>
                        </div>
                        <div style="position: absolute;right: 20px;">
                            <div style="color:#79f7f1;font-size:24px;text-align:center;">
                                {{alarmMData.user_month_num}}
                            </div>
                            <div style="color:#fff;text-align:center">本月注册</div>
                        </div>
                    </div>
                </techCard>
                <techCard title="用户性别统计">
                    <div id="sex" style="height: 18vh;"></div>
                </techCard>
                <techCard title="用户年龄分布">
                    <div id="birth" style="height:20vh"></div>
                </techCard>

            </a-layout-sider>
        </a-layout>
    </a-layout>
</template>
<script>
    import request from "../../../plugins/axios";

    import techCard from "../../../components/data/techCard.vue";
    import {L7Plot} from "@antv/l7plot";
    import {Pie, G2} from "@antv/g2plot";
    import {Column} from "@antv/g2plot";
    import * as echarts from 'echarts';

    var G = null;
    var l7obj = null;
    var piePlot = null;
    var group = null;
    var birth = null;
    // var timer = null;

    import vueSeamlessScroll from "vue-seamless-scroll";

    export default {
        name: "bigData",
        props: ["tips"],
        components: {
            techCard, //科技卡片
            vueSeamlessScroll,
        },
        data() {
            return {
                alarmMData: {
                    all_num: [],
                    today_num: [],
                    user_all_num: 0,
                    user_month_num: 0,
                },
                user: [],
                is_user: false,
                total: {
                    hyuser: '0',
                    device_bp: '0000',
                    device_weight: '0000',
                    device_watch: '0000',
                    sos: {
                        total: 0,
                        today: 0,
                        solve: 0,
                        list: [],
                    },
                    alarm: {
                        total: 0,
                        today: 0,
                        list: [],
                    },
                    bp: {
                        all: 0,
                        today: 0,
                    },
                    bs: {
                        all: 0,
                        today: 0,
                    },
                    hr: {
                        all: 0,
                        today: 0,
                    },
                    weight: {
                        all: 0,
                        today: 0,
                    },
                    device: 0,
                    device_today: 0,
                    user: 0,
                    user_month: 0,
                    user_today: 0,
                    user_list: []
                },
            };
        },
        computed: {
            classOption2() {
                return {
                    step: 5, // 数值越大速度滚动越快
                    limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
                    hoverStop: true, // 是否开启鼠标悬停stop
                    direction: 1, // 0向下 1向上 2向左 3向右
                    openWatch: true, // 开启数据实时监控刷新dom
                    singleHeight: 32, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                    singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                    waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                };
            },

        },
        created() {
            let that = this

            that.get_total();
            that.get_map();
            that.get_alarm_message();

        },
        mounted() {
            let that = this
            setInterval(function () {
                that.get_total();
                that.get_map();
                that.get_alarm_message();
            }, 15000);

            setInterval(function () {
                location.href = location.href
            }, 60000)


        },
        methods: {
            get_alarm_message() {
                let that = this
                request({
                    url: "/hospital/Screen/bigData1",
                    data: {
                        hospital: JSON.parse(localStorage.getItem('ua')).hospital,
                        hp: 1000
                    },
                }).then((ret) => {
                    that.alarmMData = ret.data
                })
            },
            set_big_data() {
                this.is_user = false;
                this.get_total();
                this.get_map();
            },
            mgo_business() {
                window.location.href = "/admin/business";
            },
            set_uid(e) {
                this.uid = e;
                localStorage.setItem('userInfo', e.id)
                window.open('/admin/indexUser')
            },
            get_map() {
                let that = this
                request({
                    url: "/hospital/Screen/map",
                    data: {
                        hospital: JSON.parse(localStorage.getItem('ua')).hospital,
                        hp: 1000
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        l7obj = new L7Plot("map", {
                            map: {
                                type: "mapbox",
                                token: "",
                                style: "blank",
                                zoom: 3,
                                center: [107.4976, 32.1697],
                                pitch: 0,
                            },
                            plots: [
                                {
                                    type: "choropleth",
                                    zIndex: 1,
                                    source: {
                                        data: [],
                                        joinBy: {
                                            sourceField: "code",
                                            geoField: "adcode",
                                        },
                                    },
                                    viewLevel: {
                                        level: "country",
                                        adcode: "100000",
                                    },
                                    style: {
                                        opacity: 1,
                                        stroke: "#26958F",
                                        lineWidth: 1,
                                        lineOpacity: 1,
                                    },
                                    chinaBorder: false,
                                    color: ["#050f22"],
                                    autoFit: true,
                                    label: null,
                                },
                                {
                                    type: "dot",
                                    zIndex: 2,
                                    source: {
                                        data: ret.data.list,
                                        parser: {
                                            type: "json",
                                            y: "LAT",
                                            x: "LON",
                                        },
                                    },
                                    size: 4,
                                    color: {
                                        field: "client_id",
                                        value: ({client_id}) => {
                                            if (client_id % 2 == 0) {
                                                return "#FEB433";
                                            } else {
                                                return "#FEB433";
                                            }
                                        },
                                    },
                                },
                            ],
                        });
                    }
                });
            },
            get_total() {
                let that = this
                request({
                    url: "/hospital/Screen/bigData2",
                    data: {
                        hospital: JSON.parse(localStorage.getItem('ua')).hospital,
                        hp: 1000
                    },
                }).then((ret) => {
                    if (ret.code == 1) {

                        let data = ret.data
                        that.total = data
                        //性别占比
                        let myChart = echarts.init(document.getElementById('sex'));
                        myChart.setOption({
                            textStyle: {
                                color: '#ffffff'
                            },
                            grid: {
                                bottom: '0',
                                top: '0',
                                containLabel: true

                            },
                            tooltip: {
                                trigger: "item",
                                formatter(name) {
                                    return (
                                        name.data.name +
                                        "|" +
                                        data.user * (name.data.value / 100) + '个' +
                                        "&zwnj; 占" +
                                        name.data.value + '%'
                                    );
                                },
                            },

                            legend: {
                                orient: 'vertical',
                                left: 'left',
                                textStyle: {
                                    color: '#ffffff'
                                },
                            },
                            series: [
                                {
                                    name: '男女比例',
                                    type: 'pie',
                                    radius: '50%',
                                    data: data.sex,
                                    color: ['#178AF3', '#E44560'],
                                    label: {
                                        show: true,
                                        formatter(param) {
                                            // correct the percentage
                                            return param.name + ' (' + param.percent * 1 + '%)';
                                        }
                                    },
                                    itemStyle: {
                                        emphasis: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }

                            ]
                        });
                        //年龄段分布
                        let birth = echarts.init(document.getElementById('birth'));
                        birth.setOption({
                            textStyle: {
                                color: '#ffffff'
                            },
                            xAxis: {
                                data: data.year_x
                            },
                            grid: [ // 网格配置项
                                {top: '10px', bottom: '30px'}, // 第一行
                            ],
                            yAxis: {},
                            series: [
                                {
                                    type: 'bar',
                                    data: data.year_y,
                                    color: ['#178AF3'],
                                    barWidth: 30
                                }
                            ]
                        });

                    }
                });
            },
        },
    };
</script>